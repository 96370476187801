<template>
  <div>
    <h3>{{ $t('reportstat.title') }}</h3>
    <br />
    <v-divider></v-divider> <br />

    <v-card outlined rounded>
      <v-card-title>
        {{ $t('all.find') }}
        <v-menu
          v-model="mdte1"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dte1"
              :label="$t('all.from')"
              prepend-icon="fa-calendar"
              readonly
              v-on="on"
              @blur="date1 = parseDate(dte1)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date1" @input="mdte1 = false" locale="ru-ru"></v-date-picker>
        </v-menu>

        <v-menu
          v-model="mdte2"
          :close-on-content-click="false"
          :nudge-right="40"
          transition="scale-transition"
          offset-y
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="dte2"
              :label="$t('all.to')"
              prepend-icon="fa-calendar"
              readonly
              v-on="on"
              @blur="date2 = parseDate(dte2)"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date2" @input="mdte2 = false" locale="ru-ru"></v-date-picker>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn dark color="primary" @click="dataLoad()">
          {{ $t('all.showreport') }}
        </v-btn>
      </v-card-title>
      <span v-show="showData">
        <v-simple-table dense>
          <tbody>
            <tr>
              <td>{{ $t('reportstat.jobs') }}</td>
              <td>{{ data.cnt_all }}</td>
            </tr>
            <tr>
              <td>{{ $t('all.cost') }}</td>
              <td>{{ data.cost }}</td>
            </tr>
            <tr>
              <td>{{ $t('reportstat.paid') }}</td>
              <td>{{ data.pay }}</td>
            </tr>
            <tr>
              <td>{{ $t('reportstat.nopaid') }}</td>
              <td>{{ data.next_pay }}</td>
            </tr>
            <tr>
              <td>{{ $t('reportstat.paybefore') }}</td>
              <td>{{ data.prev_job }}</td>
            </tr>
            <tr>
              <td>{{ $t('reportstat.smm') }}</td>
              <td>{{ data.prev_pay }}</td>
            </tr>
          </tbody>
        </v-simple-table>
        <br />
        <h4>{{ $t('reportstat.goodtitle') }}</h4>
        <v-simple-table dense>
          <tbody>
            <tr>
              <td>{{ $t('reportstat.good') }}</td>
              <td>{{ data.good_quantity }}</td>
            </tr>
            <tr>
              <td>{{ $t('all.cost') }}</td>
              <td>{{ data.good_cost }}</td>
            </tr>
            <tr>
              <td>{{ $t('reportstat.basecost') }}</td>
              <td>{{ data.good_cost_buy }}</td>
            </tr>
          </tbody> </v-simple-table
        ><br />
        <h4>{{ $t('reportstat.statustitle') }}</h4>
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="text-left">{{ $t('all.status') }}</th>
              <th class="text-left">{{ $t('all.count') }}</th>
              <th class="text-left">{{ $t('all.cost') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in data.status">
              <tr v-bind:key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.cnt }}</td>
                <td>{{ item.smm }}</td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td>{{ $t('all.total') }}</td>
              <td v-if="data.status != undefined">
                {{ data.status.reduce((accumulator, currentValue) => accumulator + currentValue.cnt, 0) }}
              </td>
              <td v-if="data.status != undefined">
                {{ data.status.reduce((accumulator, currentValue) => accumulator + currentValue.smm, 0) }}
              </td>
            </tr>
          </tfoot> </v-simple-table
        ><br />
        <h4>{{ $t('reportstat.paytpetitle') }}</h4>
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="text-left">{{ $t('reportstat.paytpe') }}</th>
              <th class="text-left">{{ $t('all.cost') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in data.pay_tbl">
              <tr v-bind:key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.smm }}</td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td>{{ $t('all.total') }}</td>
              <td v-if="data.pay_tbl != undefined">
                {{ data.pay_tbl.reduce((accumulator, currentValue) => accumulator + currentValue.smm, 0) }}
              </td>
            </tr>
          </tfoot> </v-simple-table
        ><br />
        <h4>{{ $t('reportstat.mastertitle') }}</h4>
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="text-left">{{ $t('reportstat.master') }}</th>
              <th class="text-left">{{ $t('reportstat.jobcount') }}</th>
              <th class="text-left">{{ $t('reportstat.jobsmm') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in data.master_tbl">
              <tr v-bind:key="item.id">
                <td>{{ item.master_fio }}</td>
                <td>{{ item.job }}</td>
                <td>{{ item.cost }}</td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td>{{ $t('all.total') }}</td>
              <td v-if="data.master_tbl != undefined">
                {{ data.master_tbl.reduce((accumulator, currentValue) => accumulator + currentValue.job, 0) }}
              </td>
              <td v-if="data.master_tbl != undefined">
                {{ data.master_tbl.reduce((accumulator, currentValue) => accumulator + currentValue.cost, 0) }}
              </td>
            </tr>
          </tfoot> </v-simple-table
        ><br />
        <h4>{{ $t('reportstat.servicetitle') }}</h4>
        <v-simple-table dense>
          <thead>
            <tr>
              <th class="text-left">{{ $t('all.service') }}</th>
              <th class="text-left">{{ $t('all.count') }}</th>
              <th class="text-left">{{ $t('all.cost') }}</th>
            </tr>
          </thead>
          <tbody>
            <template v-for="item in data.service_tbl">
              <tr v-bind:key="item.id">
                <td>{{ item.name }}</td>
                <td>{{ item.service_cnt }}</td>
                <td>{{ item.cost }}</td>
              </tr>
            </template>
          </tbody>
          <tfoot>
            <tr>
              <td>{{ $t('all.total') }}</td>
              <td v-if="data.service_tbl != undefined">
                {{ data.service_tbl.reduce((accumulator, currentValue) => accumulator + currentValue.service_cnt, 0) }}
              </td>
              <td v-if="data.service_tbl != undefined">
                {{ data.service_tbl.reduce((accumulator, currentValue) => accumulator + currentValue.cost, 0) }}
              </td>
            </tr>
          </tfoot>
        </v-simple-table>
      </span>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mdte1: false,
      mdte2: false,
      date1: null,
      date2: null,
      data: [],
      detail: false,
      dataDetail: [],
      showData: false,
    }
  },
  computed: {
    dte1: function() {
      return this.formatDate(this.date1)
    },
    dte2: function() {
      return this.formatDate(this.date2)
    },
  },
  components: {},
  mounted: function() {
    var dt = new Date()
    var month = dt.getMonth() + 1
    var day = dt.getDate()
    var year = dt.getFullYear()
    month = month < 10 ? '0' + month : month
    day = day < 10 ? '0' + day : day
    //                    this.dte2 = day + '.' + month + '.' + year;
    //                    this.dte1 = '01.' + month + '.' + year;
    this.date1 = year + '-' + month + '-' + '01'
    this.date2 = year + '-' + month + '-' + day
  },
  methods: {
    dteUpd1(v) {
      this.dte1 = v
    },
    dteUpd2(v) {
      this.dte2 = v
    },
    formatDate(date) {
      if (!date) {
        return null
      }
      const [year, month, day] = date.split('-')
      return `${day}.${month}.${year}`
    },
    parseDate(date) {
      if (!date) {
        return null
      }
      const [dte] = date.split(' ')
      const [day, month, year] = dte.split('.')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    updateData: function(id, smm) {
      console.log('id', id)
      console.log('smm', smm)
      console.log('data', this.data)
      var r = this.data.find(w_ => w_.service === id)
      this.$set(r, 'salary_calc', smm)
    },
    dataLoad: function() {
      var t = this
      t.$store.commit('loadingStartShow', true)
      t.showData = false
      this.$http
        .post(this.$store.state.apiUrl + 'report/statprofit', {
          dte1: t.dte1,
          dte2: t.dte2,
        })
        .then(
          response => {
            t.showData = true
            t.$store.commit('loadingStartShow', false)
            t.data = response.body
            console.log('t.data', t.data)
          },
          err => {
            console.log(err)
          },
        )
    },

    filterItem: function(e) {
      var t = ''
      e.forEach(function(entry) {
        t += entry.split(';')[0] + '; '
      })
      return t
    },
  },
}
</script>

<style></style>
